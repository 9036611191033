import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Grid, GridItem, Image, Flex, Center } from "@chakra-ui/react";
import Button from "src/components/Common/Button";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
} from "src/components/Common/Containers";
import useContentful from "src/hooks/useContentful";
import { CheckIcon, ArrowRightTealIcon } from "src/assets";
import { PageLoader } from "src/components/Homepage/SteppedFeature";
import Image1 from "src/assets/images/Image1.png";

const OPTIONS = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => <span>{children}</span>,
  },
};

const TwoColumnFeature = ({ column, page, contentType }) => {
  const { getTwoColumnMultiple } = useContentful();

  const { data: entries, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getTwoColumnMultiple(page).then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !entries;
  const data =
    entries &&
    entries.find((item) => item.fields.internalName === column).fields;

  const bannerImage = data && data["banner"]?.fields.file.url;
  const bannerPosition = data ? data["bannerPosition"] : "right";
  const buttonBackground = data
    ? `#${data["ctaBackgroundColour"]}`
    : "bg.primary";
  const backgroundColor = data ? `#${data["backgroundColour"]}` : "#FFFFFF";
  const ctaLink = data && data["ctaTarget"] ? data["ctaTarget"] : "";
  const ctaType = data && data["ctaType"] ? data["ctaType"] : "Button";

  return (
    <PageContainer
      pt="0"
      bg={backgroundColor}
      mx="0"
      mt="0"
      pb="100px"
      mb="0"
    >
      {isLoading ? (
        <PageLoader mt="100px" />
      ) : (
        <>
          <Grid
            w="full"
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            mt="80px"
            gap="30px"
          >
            {bannerPosition === "left" && (
              <GridItem
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  src={bannerImage || Image1}
                  alt="Menu Icon"
                  maxH="468px"
                />
              </GridItem>
            )}

            <GridItem
              w="full"
              textAlign="left"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <PageContainerTitle mb="30px">
                {data && documentToReactComponents(data["headline"])}
              </PageContainerTitle>

              <PageContainerDescription mb="30px">
                {data && documentToReactComponents(data["subHeadline"])}
              </PageContainerDescription>

              {data &&
                data.options?.content[0].content.map((content, index) => (
                  <Flex mb="20px" key={`grid-item-section5-${index}`}>
                    <Center
                      w="36px"
                      h="36px"
                      bg="bg.tealPrimary"
                      borderRadius="50%"
                      mr="20px"
                    >
                      <Image src={CheckIcon} alt="Check Icon" />
                    </Center>

                    <PageContainerDescription>
                      {documentToReactComponents(content, OPTIONS)}
                    </PageContainerDescription>
                  </Flex>
                ))}

              {data && data["ctaButton"] && (
                <Button
                  mr={4}
                  color="bg.tealPrimary"
                  bg={buttonBackground}
                  rightIcon={
                    <Image src={ArrowRightTealIcon} alt="Arrow Icon" />
                  }
                  type={ctaType}
                  targetUrl={ctaLink}
                  maxW="fit-content"
                  mt="10px"
                >
                  {data && data["ctaButton"]}
                </Button>
              )}
            </GridItem>

            {bannerPosition === "right" && (
              <GridItem
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  src={bannerImage || Image1}
                  alt="Menu Icon"
                  maxH="468px"
                />
              </GridItem>
            )}
          </Grid>
        </>
      )}
    </PageContainer>
  );
};

export default TwoColumnFeature;

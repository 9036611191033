import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Flex, Image, Center, Skeleton } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
  Grid3Columns,
  Grid3Item,
  Card,
} from "src/components/Common/Containers";
import { CheckIcon } from "src/assets";

const OPTIONS = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => <span>{children}</span>,
  },
};

const PageLoader = () => {
  return (
    <Flex flexDirection="column" w="full" gap="50px">
      <Skeleton w="100%" h="70px" borderRadius="10px" />
      <Flex h="full" w="full" gap="30px">
        <Skeleton w="100%" h="70px" borderRadius="10px" />
        <Skeleton w="100%" h="70px" borderRadius="10px" />
        <Skeleton w="100%" h="70px" borderRadius="10px" />
      </Flex>
    </Flex>
  );
};

const ThreeColumnSellingPoints = ({ contentType, page, ...rest }) => {
  const { getThreeColumnSellingPoints } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () =>
      getThreeColumnSellingPoints(page).then((response) => response),
    refetchOnMount: false,
  });

  const backgroundColor =
    data && !isFetching ? `#${data["backgroundColour"]}` : "#FFFFFF";
  const isLoading = isFetching || !data;

  return (
    <PageContainer minH="431px" bg={backgroundColor} mt="0" mb="0" {...rest}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {data && data["headline"] && (
            <PageContainerTitle>
              {data && documentToReactComponents(data["headline"])}
            </PageContainerTitle>
          )}

          <Grid3Columns
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, minmax(0, 1fr))" }}
            pb={{ base: "70px", md: "15px" }}
          >
            {data &&
              data.items.content[0].content.map((content, index) => (
                <Grid3Item key={`selling-point-${index}`}>
                  <Card
                    p="0"
                    mb={{ base: "10px", md: "25px" }}
                    h="80px"
                    alignItems="center"
                    bg="#FFFFFF"
                  >
                    <Flex h="full" w="full" gap="15px">
                      <Center
                        w="50px"
                        h="full"
                        bg="bg.greenPrimary"
                        borderRadius="10px 0px 0px 10px"
                      >
                        <Image src={CheckIcon} alt="check logo" />
                      </Center>

                      <PageContainerDescription>
                        {data && documentToReactComponents(content, OPTIONS)}
                      </PageContainerDescription>
                    </Flex>
                  </Card>
                </Grid3Item>
              ))}
          </Grid3Columns>
        </>
      )}
    </PageContainer>
  );
};

export default ThreeColumnSellingPoints;

import { useQuery } from "@tanstack/react-query";
import { Flex, Image, Skeleton, Grid, Text } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import Button from "src/components/Common/Button";
import { PageContainer } from "src/components/Common/Containers";
import { StarIcon, ArrowRightIcon } from "src/assets";

const PageLoader = () => {
  return (
    <Flex h="full" w="full" gap="30px">
      <Skeleton w="100%" h="150px" borderRadius="10px" />
      <Skeleton w="100%" h="150px" borderRadius="10px" />
    </Flex>
  );
};

const StarRating = () => (
  <Flex>
    {[...Array(5)].map((_, index) => (
      <Image src={StarIcon} key={`star-${index}`} w="32px" h="32px" />
    ))}
  </Flex>
);

const ReviewCard = ({ contentType, page }) => {
  const { getReviewCard } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getReviewCard(page).then((response) => response),
    refetchOnMount: false,
  });

  const reviewUrl = data && data["reviewUrl"];
  const logo = data && data["logo"]?.fields.file.url;
  const backgroundColor1 =
    data && !isFetching ? `#${data["backgroundColour"]}` : "bg.secondary";
  const backgroundColor2 =
    data && !isFetching
      ? `#${data["reviewBackgroundColour"]}`
      : "bg.yellowPrimary";
  const isLoading = isFetching || !data;

  return (
    <PageContainer mt="0" mb="0">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid
          w="full"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          borderRadius={"10px"}
        >
          <Flex
            flexDirection={"column"}
            bg={backgroundColor1}
            p={{ base: "20px", md: "40px" }}
            borderRadius={{
              base: "10px 10px 0 0",
              md: "10px 0 0 10px",
            }}
          >
            <Image src={logo} w="161px" h="40px" />

            <Text
              color="bg.greenPrimary"
              fontSize={"40px"}
              lineHeight="40px"
              fontWeight={500}
              textAlign={"left"}
              mt="10px"
            >
              {data && data["headline"]}
            </Text>

            <Button
              mt="30px"
              bg="#FFFFFF"
              color="bg.primary"
              alignSelf="flex-start"
              rightIcon={<Image src={ArrowRightIcon} alt="Arrow Icon" />}
              targetUrl={reviewUrl}
            >
              {data && data["cta"]}
            </Button>
          </Flex>

          <Flex
            flexDirection="column"
            bg={backgroundColor2}
            p={{ base: "20px", md: "40px" }}
            borderRadius={{
              base: "0 0 10px 10px",
              md: "0 10px 10px 0",
            }}
          >
            <Flex
              mb="25px"
              justifyContent="space-between"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Text
                fontSize="22px"
                fontWeight={500}
                lineHeight="32px"
                textAlign="left"
                color="bg.primary"
              >
                {data && data["reviewerName"]}
              </Text>
              <StarRating />
            </Flex>
            <Text
              fontSize="22px"
              fontWeight={400}
              lineHeight="32px"
              textAlign="left"
              color="bg.primary"
            >
              {data && data["review"]}
            </Text>
          </Flex>
        </Grid>
      )}
    </PageContainer>
  );
};

export default ReviewCard;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "src/hooks/useContentful";
import { Image, Skeleton, SkeletonText } from "@chakra-ui/react";
import Button from "src/components/Common/Button";
import {
  PageContainer,
  PageContainerTitle,
} from "src/components/Common/Containers";
import { ArrowRightTealIcon } from "src/assets";
import QuestionsAccordion from "./QuestionsAccordion";

const PageLoader = () => {
  return (
    <>
      <Skeleton w="100%" h="50px" borderRadius="10px" mb="40px" />
      <SkeletonText
        w="100%"
        noOfLines={4}
        spacing="4"
        skeletonHeight="4"
        mb="40px"
      />
      <Skeleton w={{ base: "90%", md: "20%" }} h="50px" borderRadius="10px" />
    </>
  );
};

const FeaturedFAQ = ({ contentType, page, ...rest }) => {
  const { getFeaturedFAQ } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getFeaturedFAQ(page).then((response) => response),
    refetchOnMount: false,
  });

  const ctaLink = data && data["ctaTarget"] ? data["ctaTarget"] : "/faq";
  const isLoading = isFetching || !data;

  return (
    <PageContainer
      bg="bg.yellowPrimary"
      mx="0"
      pb="100px"
      mb="0"
      alignItems="flex-start"
      {...rest}
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageContainerTitle mb="34px">
            {data && documentToReactComponents(data["headline"])}
          </PageContainerTitle>

          <QuestionsAccordion faqs={data && data["faQs"]} />

          <Button
            mr={4}
            mt="50px"
            color="bg.tealPrimary"
            bg="bg.primary"
            rightIcon={<Image src={ArrowRightTealIcon} alt="Arrow Icon" />}
            targetUrl={ctaLink}
          >
            {data && data["ctaButton"]}
          </Button>
        </>
      )}
    </PageContainer>
  );
};

export default FeaturedFAQ;

import { useQuery } from "@tanstack/react-query";
import useContentful from "src/hooks/useContentful";
import { Box, Grid, GridItem, Skeleton, Flex } from "@chakra-ui/react";
import {
  PageContainer,
  PageContainerTitle,
} from "src/components/Common/Containers";

const PageLoader = () => {
  return (
    <>
      <Skeleton w="50%" h="40px" borderRadius="10px" mb="40px" />
      <Skeleton w="full" h="100px" borderRadius="10px" />
    </>
  );
};

const StatsCallout = ({ contentType, page }) => {
  const { getStatsCallout } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getStatsCallout(page).then((response) => response),
    refetchOnMount: false,
  });

  const backgroundColor =
    data && !isFetching ? `#${data["backgroundColour"]}` : "bg.primary";
  const isLoading = isFetching || !data;

  const formatBackgroundColor = (hexCode) => {
    if (!hexCode) {
      return null;
    }

    return `#${hexCode}`;
  };

  return (
    <PageContainer pt="0" mt="0" mb="100px">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex
          w="full"
          flexDirection="column"
          bg={backgroundColor}
          borderRadius="10px"
          padding="60px 40px"
        >
          <PageContainerTitle fontSize="24px" color="white">
            {data && data["headline"]}
          </PageContainerTitle>

          <Grid
            w="full"
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
            mt="60px"
            gap="30px"
          >
            {data &&
              data.stats?.map(({ fields }, index) => (
                <GridItem
                  w="full"
                  key={`grid-item-stats-${index}`}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    fontSize="48px"
                    color={
                      formatBackgroundColor(fields["statsValueTextColor"]) ||
                      "#FFFFFF"
                    }
                  >
                    {fields["statsValue"]}
                  </Box>

                  <Box fontSize="16px" color="#FFFFFF">
                    {fields["label"]}
                  </Box>
                </GridItem>
              ))}
          </Grid>
        </Flex>
      )}
    </PageContainer>
  );
};

export default StatsCallout;

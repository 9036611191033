import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ContentBlock from "src/components/Common/ContentBlock";

const PrivacyPolicy = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "privacyPolicyPageTitle", description: "privacyPolicyMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="privacyPolicyHeader" page="Privacy Policy" />

      {/* Content Type: Content Block */}
      <ContentBlock contentType="privacyPolicyContent" page="Privacy Policy" />
    </>
  );
};

export default PrivacyPolicy;

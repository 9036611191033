import { Flex, Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";

export const PageContainer = ({ children, ...rest }) => {
  const [isLessThan1200] = useMediaQuery("(max-width: 1200px)");

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={{ base: "0", md: "100px" }}
      px={{ base: "20px", md: isLessThan1200 ? "50px" : "170px" }}
      mb={{ base: "0", md: "100px" }}
      pt="120px"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export const PageContainerTitle = ({ children, ...rest }) => {
  return (
    <Box
      color="bg.primary"
      fontSize={{ base: "32px", md: "40px" }}
      fontWeight={500}
      lineHeight="43px"
      {...rest}
    >
      {children}
    </Box>
  );
};

export const PageContainerDescription = ({ children, ...rest }) => {
  return (
    <Flex
      alignItems="center"
      fontSize="20px"
      lineHeight="26px"
      color="bg.primary"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export const Card = ({ children, ...rest }) => {
  return (
    <Flex
      p="40px"
      justifyContent="center"
      flexDirection="column"
      borderRadius="10px"
      boxShadow="5px 10px 8px -3px rgba(0, 0, 0, 0.10);"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export const Grid3Columns = ({ children, ...rest }) => {
  return (
    <Grid
      w="full"
      templateColumns="repeat(3, 1fr)"
      mt="80px"
      gap="30px"
      pb="15px"
      px="5px"
      {...rest}
    >
      {children}
    </Grid>
  );
};

export const Grid3Item = ({ children, ...rest }) => {
  return (
    <GridItem w="full" flexDirection="column" gridAutoRows="1fr" {...rest}>
      {children}
    </GridItem>
  );
};

const Container = ({ children, pageId }) => {
  return (
    <Flex id={pageId} px={4} minH="40vh" m={0}>
      {children}
    </Flex>
  );
};

export default Container;

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Image,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "src/assets";

const QuestionsAccordion = ({ faqs = [] }) => {
  return (
    <Accordion allowMultiple w="full">
      {faqs.map(({ fields }, index) => (
        <AccordionItem
          key={`section-12-${index}`}
          border="none"
          borderBottom="1px solid #B3B3B3"
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton key={`section-12-`} minH="50px" alignItems="center">
                <Image
                  src={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                  mr="10px"
                  alt="Car Icon"
                />
                <Box fontSize="24px" lineHeight="32px" color="bg.primary" textAlign={"left"}>
                  {fields && documentToReactComponents(fields["question"])}
                </Box>
              </AccordionButton>
              <AccordionPanel
                pl="50px"
                fontSize="16px"
                lineHeight="24px"
                color="bg.primary"
                textAlign="left"
              >
                {fields && documentToReactComponents(fields["answer"])}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default QuestionsAccordion;

import { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Flex, Center, Image, Box } from "@chakra-ui/react";
import Button from "src/components/Common/Button";
import useContentful from "src/hooks/useContentful";
import { ThemeContext } from "src/providers/ThemeProvider";
import { CheckIcon } from "src/assets";
import { GRADIENT_BACKGROUND } from "src/utils/constant";
import AssetSelector from "./AssetSelector";
import HeaderLoader from "./HeaderLoader";
import { getImagePosition } from "src/utils/helpers";

const Headline = ({ contentType = "homepageEntry", page }) => {
  const { getHomepageEntry } = useContentful();
  const { setHeaderBackground } = useContext(ThemeContext);

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getHomepageEntry(page).then((response) => response),
    refetchOnMount: false,
  });

  const backgroundColor =
    data && data["backgroundType"] === "Gradient"
      ? GRADIENT_BACKGROUND
      : data
      ? `#${data["backgroundColour"]}`
      : "none";
  const calloutIcon = data && data["calloutIcon"]?.fields.file.url;
  const isLoading = isFetching || !data;
  const ctaText = data && data["ctaText"] ? data["ctaText"] : "Compare Loans";
  const ctaLink = data && data["ctaTarget"] ? data["ctaTarget"] : "";
  const isPlainHeader =
    data &&
    data["ctaType"] === "None" &&
    !data["bannerImageLeft"] &&
    !data["bannerImageRight"];
  const bannerLeftPosition =
    data && getImagePosition(data["bannerImageLeftPosition"]);
  const bannerRightPosition =
    data && getImagePosition(data["bannerImageRightPosition"]);

  useEffect(() => {
    if (!isLoading) {
      setHeaderBackground(
        data["backgroundType"] === "Gradient" ? "none" : "#FFFFFF"
      );
    }
  }, [data, isLoading, setHeaderBackground]);

  const ImageBanner = ({ image, ...rest }) => {
    return (
      <Flex alignItems="flex-end" maxW="320px" {...rest}>
        <Image src={image} alt="Banner" style={{ objectFit: "content" }} />
      </Flex>
    );
  };

  return (
    <Flex
      id="homepage"
      minH={isPlainHeader ? "0" : "100vh"}
      pt={{ base: "150px", md: isPlainHeader ? "220px" : "150px" }}
      bg={backgroundColor}
      bgBlendMode="multiply"
      justifyContent="space-between"
      flexDirection={{ base: "column", md: "row" }}
      gap="30px"
    >
      {isLoading ? (
        <HeaderLoader />
      ) : (
        <>
          <Flex flex={1} display={{ base: "none", md: "flex" }}>
            {data && data["bannerImageLeft"] && (
              <ImageBanner
                alignItems={bannerLeftPosition}
                image={data["bannerImageLeft"].fields.file.url}
              />
            )}
          </Flex>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={2}
            px={{ base: "20px", md: "0" }}
          >
            <Box
              color="bg.primary"
              mb="50px"
              fontWeight={{ base: "600", md: "500" }}
              fontSize={{ base: "48px", md: "96px" }}
              lineHeight={{ base: "56px", md: "82px" }}
            >
              {data && documentToReactComponents(data["headline"])}
            </Box>

            <Box
              mb="50px"
              fontSize={{ base: "21px", md: "26px" }}
              color="bg.primary"
              lineHeight="32px"
            >
              {data && documentToReactComponents(data["subHeadline"])}
            </Box>

            {!isLoading && data["ctaType"] === "Asset Selector" && (
              <AssetSelector />
            )}

            {!isLoading && data["ctaType"] === "CTA" && (
              <Button
                mr={4}
                color="bg.tealPrimary"
                bg="bg.primary"
                targetUrl={ctaLink}
              >
                {ctaText}
              </Button>
            )}

            {data && data["callout"] && (
              <Flex mt="50px" alignItems="center" mb="30px">
                <Center mr="16px">
                  <Image
                    src={calloutIcon || CheckIcon}
                    alt="Check Icon"
                    maxW="30px"
                    maxH="30px"
                  />
                </Center>

                <Box fontSize="18px" color="bg.primary" lineHeight="28px">
                  {data && documentToReactComponents(data["callout"])}
                </Box>
              </Flex>
            )}
          </Flex>

          <Flex
            flex={1}
            display={{ base: "none", md: "flex" }}
            justifyContent="flex-end"
          >
            {data && data["bannerImageRight"] && (
              <ImageBanner
                alignItems={bannerRightPosition}
                image={data["bannerImageRight"].fields.file.url}
              />
            )}
          </Flex>

          <Flex flex={1} display={{ base: "block", md: "none" }}>
            {data && data["bannerImageMobile"] && (
              <Image
                src={data["bannerImageMobile"].fields.file.url}
                alt="Banner"
                style={{ objectFit: "content" }}
              />
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default Headline;

import { useState, createContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import useContentful from "src/hooks/useContentful";

export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const { getNavigationSettings } = useContentful();

  const { data: navigationSettings, isFetching } = useQuery({
    queryKey: ["navigationSettings"],
    queryFn: () => getNavigationSettings().then((response) => response),
    refetchOnMount: false,
  });

  const setPageSettings = ({ title, description }) => {
    setPageTitle(title);
    setMetaDescription(description);
  };

  const value = {
    navigationSettings,
    isPageReady: !isFetching && navigationSettings,
    setPageSettings,
  };
  
  return (
    <AppContext.Provider value={value}>
      <Helmet>
        <title>{navigationSettings && navigationSettings[pageTitle]}</title>
        <meta name="description" content={navigationSettings && navigationSettings[metaDescription]}/>
      </Helmet>

      {children}
    </AppContext.Provider>
  );
};

import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import HeroCallout from "src/components/Common/HeroCallout";
import FinanceCategory from "src/components/Common/FinanceCategory";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";
import ReviewCard from "src/components/Common/ReviewCard";
import ThreeColumnFeature from "src/components/Common/ThreeColumnFeature";
import HeroCard from "./HeroCard";

const LoanExperts = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "loanExpertsPageTitle", description: "loanExpertsMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="loanExpertHeader" page="Loan Experts" />

      {/* Content Type: Three Column Feature */}
      <ThreeColumnFeature contentType="loanExpertTCF" page="Loan Experts" mb="0" />

      {/* Content Type: Two Column Feature */}
      <HeroCard
        contentType="loanExpertsTwoColumnFeature"
        page="Loan Experts"
        name="Loan Experts: Two Column Feature"
      />

      {/* Content Type: Hero Callout */}
      <HeroCallout contentType="loanExpertHeroCallout" page="Loan Experts" mb="0" />

      {/* Content Type: Finance Categories */}
      <FinanceCategory contentType="loanExpertFinanceCategory" page="Loan Experts" mb="0" />

      {/* Content Type: Featured Review Card */}
      <ReviewCard contentType="loanExpertReviewCard" page="Loan Experts" />

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="loanExpertFeaturedFAQ" page="Loan Experts" />
    </>
  );
};

export default LoanExperts;

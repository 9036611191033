import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";

import Headline from "src/components/Common/PageHeader";
import TwoColumnFeature from "src/components/Common/TwoColumnFeature";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";
import ThreeColumnFeature from "./ThreeColumnFeature";
import SteppedFeature from "./SteppedFeature";
import Callout from "./Callout";
import FeaturedProducts from "./FeaturedProducts";
import HeroCard from "./HeroCard";
import LogoCallout from "./LogoCallout";
import FeaturedArticle from "./FeaturedArticle";

const Homepage = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({
      title: "homepagePageTitle",
      description: "homepageMetaDescription",
    });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="homepageEntry" page="homepage" />

      {/* Content Type: Three Column Feature */}
      <ThreeColumnFeature />

      {/* Content Type: Two ColumnStepped Feature */}
      <SteppedFeature />

      {/* Content Type: Hero Callout, CTA Callout */}
      <Callout />

      {/* Content Type: Two Column Featur */}
      <TwoColumnFeature
        column="Homepage: Two Column Feature 1"
        contentType="homepageTwoColumn"
        page="Homepage"
      />
      <TwoColumnFeature
        column="Homepage: Two Column Feature 2"
        contentType="homepageTwoColumn"
        page="Homepage"
      />
      <TwoColumnFeature
        column="Homepage: Two Column Feature 3"
        contentType="homepageTwoColumn"
        page="Homepage"
      />

      {/* Content Type: Featured Products */}
      <FeaturedProducts />

      {/* Content Type: Hero Card */}
      <HeroCard />

      {/* Content Type: Logo Callout */}
      <LogoCallout />

      {/* Content Type: Featured Article */}
      {/* <FeaturedArticle /> */}

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="homepageFeaturedFAQ" page="Homepage" mt="0" />
    </>
  );
};

export default Homepage;

import { Image, Flex, Text, Grid, Center, Link } from "@chakra-ui/react";
import { PageContainer } from "src/components/Common/Containers";
import {
  SimplifyWhiteLogo,
  NZIcon,
  PhonePrimaryIcon,
  MailIcon,
  InstagramIcon,
  LinkedInIcon,
  FacebookIcon,
} from "src/assets";
import { useRedirect } from "src/hooks/useRedirect";

const aboutItems = [
  { text: "About Us", route: "/about/about-us" },
  { text: "Loan Experts", route: "/loan-experts" },
  { text: "Contact Us", route: "/about/contact-us" },
  {
    text: "Partners",
    route: "https://simplify.co.nz/partners",
  },
  { text: "FAQ", route: "/faq" },
];

const productsItems = [
  { text: "Car Finance", route: "/finance/car-finance" },
  { text: "Boat Finance", route: "/finance/boat-finance" },
  { text: "Motorbike Finance", route: "/finance/motorbike-finance" },
  { text: "Caravan Finance", route: "/finance/caravan-finance" },
  { text: "Business Finance", route: "/finance/business-finance" },
];

const resourcesItems = [
  { text: "Privacy Policy", route: "/about/privacy-policy" },
  { text: "Fees & Charges", route: "/about/fees-and-charges" },
  { text: "Terms of Use", route: "/about/terms-of-use" },
  {
    text: "Licensing information & Disclosure Statement",
    route: "/about/license-disclosure",
  },
];

const socialItems = [
  {
    text: "Instagram",
    icon: InstagramIcon,
    route: "https://www.instagram.com/simplifycarfinance/",
  },
  {
    text: "LinkedIn",
    icon: LinkedInIcon,
    route: "https://www.linkedin.com/company/simplify-holdings-international/",
  },
  {
    text: "Facebook",
    icon: FacebookIcon,
    route: "https://www.facebook.com/simplifycarfinance/",
  },
];

const FooterItems = ({ title, items }) => {
  const { handleNavigate } = useRedirect();

  return (
    <Flex flexDirection="column" alignItems="flex-start" fontSize="16px">
      <Text color="bg.greenPrimary" mb="15px" fontWeight={600}>
        {title}
      </Text>
      {items.map(({ text, route }) => (
        <Text
          key={`footer-${text}`}
          color="white"
          lineHeight="28px"
          cursor="pointer"
          _hover={{
            fontWeight: 500,
          }}
          onClick={() => handleNavigate(route)}
          textAlign="left"
        >
          {text}
        </Text>
      ))}
    </Flex>
  );
};

const Contacts = () => {
  return (
    <Flex flexDirection="column" alignItems="flex-start" fontSize="16px">
      <Text color="bg.greenPrimary" mb="15px" fontWeight={600}>
        Contact
      </Text>

      <Link
        href="tel:0800 001 561"
        mb="10px"
        display="flex"
        _hover={{ textDecoration: "none" }}
      >
        <Center w="30px" h="30px" borderRadius="50%" bg="bg.tealPrimary">
          <Image src={PhonePrimaryIcon} alt="simplify logo" />
        </Center>
        <Text color="white" lineHeight="28px" ml="10px">
          0800 001 561
        </Text>
      </Link>

      <Link
        href="mailto:team@simplify.co.nz"
        display="flex"
        _hover={{ textDecoration: "none" }}
      >
        <Center w="30px" h="30px" borderRadius="50%" bg="bg.tealPrimary">
          <Image src={MailIcon} alt="simplify logo" />
        </Center>
        <Text color="white" lineHeight="28px" ml="10px">
          team@simplify.co.nz
        </Text>
      </Link>

      <Flex flex={1} gap="10px" mt="20px">
        {socialItems.map(({ text, icon, route }) => (
          <Center
            key={`social-${text}`}
            w="30px"
            h="30px"
            borderRadius="50%"
            bg="bg.tealPrimary"
            cursor="pointer"
            transition="0.5s ease"
            _hover={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
            onClick={() => window.open(route, "_blank")}
          >
            <Image src={icon} alt="simplify logo" />
          </Center>
        ))}
      </Flex>
    </Flex>
  );
};

const Footer = () => {
  return (
    <PageContainer
      bg="bg.primary"
      mx="0"
      py="80px"
      mb="0"
      mt="0"
      justifyContent="flex-start"
    >
      <Flex w="full" justifyContent="space-between">
        <Flex flex={1}>
          <Image
            src={SimplifyWhiteLogo}
            alt="simplify logo"
            w="132px"
            h="35px"
            ml={2}
            objectFit="cover"
          />
        </Flex>

        <Flex flex={1} justifyContent="flex-end" alignItems="center">
          <Image src={NZIcon} alt="simplify logo" mr="20px" />
          <Text color="#FFF" fontSize="18px">
            New Zealand
          </Text>
        </Flex>
      </Flex>

      <Grid
        w="full"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
        mt="38px"
        gap="30px"
        overflowX="auto"
      >
        <FooterItems title="About" items={aboutItems} />
        <FooterItems title="Products" items={productsItems} />
        <FooterItems title="Resources" items={resourcesItems} />
        <Contacts />
      </Grid>
    </PageContainer>
  );
};

export default Footer;

import { useQuery } from "@tanstack/react-query";
import { MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Box, SkeletonText } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import { PageContainer } from "src/components/Common/Containers";

const OPTIONS = {
  renderMark: {
    [MARKS.UNDERLINE]: (text) => (
      <span style={{ textDecoration: "underline", color: "#5CE555" }}>
        {text}
      </span>
    ),
  },
};

const HeroCallout = ({ contentType, page }) => {
  const { getHeroCallout } = useContentful();

  const { data: heroCallout, isFetching: calloutIsFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getHeroCallout(page).then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = calloutIsFetching || !heroCallout;

  return (
    <PageContainer mb="0">
      {isLoading ? (
        <SkeletonText noOfLines={2} w="full" spacing="4" skeletonHeight="10" />
      ) : (
        <>
          <Box
            fontSize={{ base: "26px", md: "54px" }}
            fontWeight={500}
            lineHeight={{ base: "40px", md: "60px" }}
            color="bg.primary"
          >
            {heroCallout &&
              documentToReactComponents(heroCallout["headline"], OPTIONS)}
          </Box>
        </>
      )}
    </PageContainer>
  );
};

export default HeroCallout;

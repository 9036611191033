import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "./providers/ThemeProvider";
import { AppProvider } from "./providers/AppProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { theme } from "./styles/theme";
import routes from "./utils/routes";

import "./styles/styles.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <AppProvider>
        <ThemeProvider>
          <RouterProvider router={routes} />
        </ThemeProvider>
      </AppProvider>
    </ChakraProvider>

    {process.env.REACT_APP_ENV === "dev" && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
);

export default App;

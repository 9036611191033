import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Image,
  Flex,
  Text,
  Center,
} from "@chakra-ui/react";
import { AppContext } from "src/providers/AppProvider";
import { pages } from "src/utils/constant";
import { RedirectToIcon } from "src/assets";

const NavLinkMobile = ({ handleNavigate }) => {
  const { pathname } = useLocation();
  const { navigationSettings } = useContext(AppContext);
  const [isMobileView] = useMediaQuery("(max-width: 766px)");
  const offsetAmount = isMobileView ? 78 : 132;

  return (
    <>
      {pages.map(({ text, route, subPages }) => {
        if (!subPages) {
          return (
            <Flex
              p="20px 15px 20px 10px"
              color="#000"
              fontSize={"16px"}
              _hover={{ bg: "#f2f2f2" }}
              transition="0.5s ease"
              textAlign="left"
              cursor="pointer"
              bg={pathname.includes(route) ? "#f2f2f2" : "bg.white"}
              onClick={() => handleNavigate(route)}
              key={route}
            >
              {text}
            </Flex>
          );
        }

        return (
          <Accordion allowMultiple w="full" key={route}>
            <AccordionItem w="full" border="none" key={route}>
              <AccordionButton p="20px 15px 20px 10px" w="full">
                <Flex
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text color="#000" fontSize={"16px"}>
                    {text}
                  </Text>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>

              {subPages && (
                <AccordionPanel p="0" bg="#F9FAFB">
                  {subPages.map(({ text, route, icon }) => (
                    <Flex
                      key={text}
                      offset={offsetAmount}
                      py="10px"
                      cursor={"pointer"}
                      bg={pathname.includes(route) ? "#f2f2f2" : "bg.white"}
                      transition="0.5s ease"
                      _hover={{
                        bg: "#f2f2f2",
                      }}
                      onClick={() => handleNavigate(route)}
                    >
                      <Flex alignItems="center">
                        <Center
                          bg="bg.bluePrimary"
                          borderRadius="6px"
                          w="50px"
                          h="50px"
                          mr="20px"
                        >
                          <Image
                            src={
                              navigationSettings && navigationSettings[icon]
                                ? navigationSettings[icon].fields.file.url
                                : RedirectToIcon
                            }
                          />
                        </Center>
                        <Text color="#000" fontSize={"16px"}>
                          {text}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        );
      })}
    </>
  );
};

export default NavLinkMobile;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import {
  Flex,
  Grid,
  GridItem,
  Center,
  Image,
  Skeleton,
} from "@chakra-ui/react";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
} from "src/components/Common/Containers";
import useContentful from "src/hooks/useContentful";
import { ArrowUpIcon } from "src/assets";

const OPTIONS = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul style={{ listStyleType: "none", lineHeight: "35px" }}>{children}</ul>
    ),
  },
};

export const PageLoader = () => {
  return (
    <>
      <Skeleton
        w={{ base: "90%", md: "50%" }}
        h="80px"
        borderRadius="10px"
        mb="50px"
      />

      <Flex h="full" w="full" gap="30px">
        <Skeleton
          w={{ base: "90%", md: "100%" }}
          h="300px"
          borderRadius="10px"
        />
        <Skeleton
          w={{ base: "90%", md: "100%" }}
          h="300px"
          borderRadius="10px"
        />
        <Skeleton
          w={{ base: "90%", md: "100%" }}
          h="300px"
          borderRadius="10px"
        />
      </Flex>
    </>
  );
};

const Card = ({ children, ...rest }) => {
  return (
    <Flex
      minW={{ base: "full", md: "auto" }}
      p={{ base: "20px", md: "40px" }}
      justifyContent="center"
      flexDirection="column"
      borderRadius="10px"
      boxShadow="5px 10px 8px -3px rgba(0, 0, 0, 0.10);"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const ThreeColumnFeature = ({ contentType, page, ...rest }) => {
  const { getHomepageThreeColumn } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getHomepageThreeColumn(page).then((response) => response),
    refetchOnMount: false,
  });

  const backgroundColor =
    data && !isFetching ? `#${data["backgroundColour"]}` : "#FFFFFF";
  const isLoading = isFetching || !data;

  return (
    <PageContainer mt="0" mb={{ base: "80px", md: "100px" }} {...rest}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageContainerTitle>
            {data && documentToReactComponents(data["headline"])}
          </PageContainerTitle>

          <Grid
            w="full"
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
            mt="80px"
            gap="30px"
            p="10px"
          >
            {data &&
              data["columns"].map(({ fields }, index) => (
                <GridItem
                  w="full"
                  flexDirection="column"
                  gridAutoRows="1fr"
                  key={`grid-item-section2-${index}`}
                  mb={{ base: "40px", md: "0px" }}
                >
                  <Card
                    mb="25px"
                    bg={backgroundColor}
                    h="100%"
                    justifyContent="flex-start"
                  >
                    <Center
                      w="72px"
                      h="72px"
                      borderRadius={"50%"}
                      bg="bg.greenPrimary"
                    >
                      <Image
                        src={fields["icon"]?.fields.file.url || ArrowUpIcon}
                        w={fields["icon"] ? "62px" : "42px"}
                        h={fields["icon"] ? "62px" : "42px"}
                        alt="arrow"
                      />
                    </Center>

                    <PageContainerTitle mb="25px" mt="30px" textAlign="left">
                      {documentToReactComponents(fields["headline"])}
                    </PageContainerTitle>

                    <PageContainerDescription textAlign="left">
                      {documentToReactComponents(
                        fields["subHeadline"],
                        OPTIONS
                      )}
                    </PageContainerDescription>
                  </Card>
                </GridItem>
              ))}
          </Grid>
        </>
      )}
    </PageContainer>
  );
};

export default ThreeColumnFeature;

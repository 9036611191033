import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "src/hooks/useContentful";
import { Box, Grid, GridItem, Image, Skeleton } from "@chakra-ui/react";
import {
  PageContainer,
  PageContainerTitle,
} from "src/components/Common/Containers";
import AutoTrader from "src/assets/images/auto-trader.png";

const PageLoader = () => {
  return (
    <>
      <Skeleton w="50%" h="40px" borderRadius="10px" mb="40px" />
      <Skeleton w="full" h="100px" borderRadius="10px" />
    </>
  );
};

const LogoCallout = () => {
  const { getHomepageLogoCallout } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: ["homepageLogoCallout"],
    queryFn: () => getHomepageLogoCallout().then((response) => response),
    refetchOnMount: false,
  });
  const isLoading = isFetching || !data;

  return (
    <PageContainer pt="100px" mt="0" mb="0" pb="100px" bg="#F9F8F5">
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageContainerTitle fontSize="24px">
            {data && documentToReactComponents(data["headline"])}
          </PageContainerTitle>

          <Grid
            w="full"
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(6, 1fr)" }}
            mt="80px"
            gap="30px"
          >
            {data &&
              data.logos.map(({ fields }, index) => (
                <GridItem
                  w="full"
                  key={`grid-item-section10-${index}`}
                  display="flex"
                  justifyContent="center"
                  bg="#FFFFFF"
                  borderRadius="6px"
                  boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                >
                  <Box>
                    <Image
                      src={fields?.file.url || AutoTrader}
                      alt="Auto trader Icon"
                    />
                  </Box>
                </GridItem>
              ))}
          </Grid>
        </>
      )}
    </PageContainer>
  );
};

export default LogoCallout;

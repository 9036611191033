import { useState, createContext } from "react";

export const ThemeContext = createContext();
export const ThemeProvider = ({ children }) => {
  const [headerBackground, setHeaderBackground] = useState("none");

  const value = {
    headerBackground: headerBackground,
    setHeaderBackground: setHeaderBackground,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import {
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  Center,
} from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import Button from "src/components/Common/Button";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
} from "src/components/Common/Containers";
import { CheckIcon, ArrowRightIcon } from "src/assets";

const OPTIONS = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => <span>{children}</span>,
  },
};

const PageLoader = () => {
  return (
    <Flex flexDirection="column" w="full" gap="50px">
      <SkeletonText noOfLines={2} spacing="4" skeletonHeight="6" mb="10px" />
      <Flex h="full" w="full" gap="30px" mb="30px">
        <Skeleton w="100%" h="120px" borderRadius="10px" />
      </Flex>
      <Skeleton w={{ base: "60%", md: "20%" }} h="70px" borderRadius="10px" />
    </Flex>
  );
};

const HeroCard = ({ contentType, page, name }) => {
  const { getTwoColumnFeature } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getTwoColumnFeature(page, name).then((response) => response),
    refetchOnMount: false,
  });

  const bannerImage = data && data["banner"]?.fields.file.url;
  const bannerPosition = data ? data["bannerPosition"] : "right";
  const backgroundColor =
    data && !isFetching ? `#${data["backgroundColour"]}` : "#003B3F";
  const buttonBG =
    data && !isFetching ? `#${data["ctaBackgroundColour"]}` : "#FFFFFF";
  const isLoading = isFetching || !data;
  const ctaLink = (data && data["ctaTarget"]) ? data["ctaTarget"] : "";

  return (
    <PageContainer mt="0" mb="0">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex
          w="full"
          borderRadius="10px"
          p={{ base: "20px", md: "50px" }}
          bg={backgroundColor}
          justifyContent="space-between"
          flexDirection={{ base: "column", md: "row" }}
          gap={{ base: "25px", md: "90px" }}
        >
          {bannerPosition === "left" && (
            <Center minW="243px" minH="243px">
              <Image src={bannerImage} alt="Banner" />
            </Center>
          )}

          <Flex w="full" flexDirection="column">
            <PageContainerTitle
              w="full"
              textAlign="left"
              mb="30px"
              color="bg.greenPrimary"
            >
              {data && documentToReactComponents(data["headline"])}
            </PageContainerTitle>

            <PageContainerDescription
              w="full"
              textAlign="left"
              mb="40px"
              color="#FFF"
            >
              {data && documentToReactComponents(data["subHeadline"])}
            </PageContainerDescription>

            {data &&
              data.options.content[0].content.map((content, index) => (
                <Flex key={`three-columncard-${index}`}>
                  <Flex mb="20px" border="none" flexDirection="row">
                    <Center
                      minW="36px"
                      minH="36px"
                      maxW="36px"
                      maxH="36px"
                      mr="20px"
                      borderRadius={"50%"}
                      bg="bg.greenPrimary"
                    >
                      <Image src={CheckIcon} w="24px" h="24px" alt="arrow" />
                    </Center>

                    <PageContainerDescription
                      w="full"
                      textAlign="left"
                      fontSize="16px"
                      color="bg.greenBase"
                    >
                      {documentToReactComponents(content, OPTIONS)}
                    </PageContainerDescription>
                  </Flex>
                </Flex>
              ))}

            <Button
              mt="10px"
              bg={buttonBG}
              color="bg.primary"
              alignSelf="flex-start"
              rightIcon={<Image src={ArrowRightIcon} alt="Arrow Icon" />}
              targetUrl={ctaLink}
            >
              {data && data["ctaButton"]}
            </Button>
          </Flex>

          {bannerPosition === "right" && (
            <Center minW="243px" minH="243px">
              <Image src={bannerImage} alt="Banner" />
            </Center>
          )}
        </Flex>
      )}
    </PageContainer>
  );
};

export default HeroCard;

import {  extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    bg: {
      primary: "#003B3F",
      secondary: "#042f35",
      tealPrimary: "#0FFFFF",
      greenPrimary: "#5CE555",
      greenBase: "#D8F8D5",
      greyPrimary: "#B3B3B3",
      yellowPrimary: "#F9F8F5",
      bluePrimary: "#F5F5F7"
    },
    text: {
      white: "#FFFFFF",
      green: "#5CE555",
    },
  },
});

import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ThreeColumnSellingPoints from "src/components/Common/ThreeColumnSellingPoints";
import ReviewCard from "src/components/Common/ReviewCard";
import ThreeColumnFeature from "src/components/Common/ThreeColumnFeature";
import SimpleCTA from "src/components/Common/SimpleCTA";
import CTACallout from "src/components/Common/CTACallout";
import ThreeColumnCard from "../common/ThreeColumnCard";
import HeroCard from "src/components/Common/HeroCard";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";

const BoatFinance = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "boatPageTitle", description: "boatMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="boatFinanceHeader" page="Boat Finance" />

      {/* Content Type: Three Column Feature */}
      <ThreeColumnFeature contentType="boatFinanceTCF" page="Boat Finance" />

      {/* Content Type: CTA Callout */}
      <CTACallout contentType="boatCTACallout" page="Boat Finance" />

      {/* Content Type: Three Column Selling Points */}
      <ThreeColumnSellingPoints contentType="boatFinanceTCSellingPoints" page="Boat Finance" />

      {/* Content Type: Three Column Selling Points */}
      <SimpleCTA contentType="boatFinanceSimpleCTA" page="Boat Finance" />

      {/* Content Type: Three Column Card */}
      <ThreeColumnCard contentType="boatFinanceTCCard" page="Boat Finance" />

      {/* Content Type: Two Column Feature */}
      <HeroCard
        contentType="boatFinanceTwoColumnFeature"
        page="Boat Finance"
        name="Boat Finance: Two Column Feature"
      />

      {/* Content Type: Featured Review Card */}
      <ReviewCard contentType="boatFinanceReviewCard" page="Boat Finance" />

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="boatFinanceFeaturedFAQ" page="Boat Finance" />
    </>
  );
};

export default BoatFinance;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Flex, Image, Skeleton, SkeletonText, Center } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
  Grid3Columns,
  Grid3Item,
  Card,
} from "src/components/Common/Containers";
import { ArrowUpIcon } from "src/assets";

const OPTIONS = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => <span>{children}</span>,
  },
};

const PageLoader = () => {
  return (
    <Flex flexDirection="column" w="full" gap="50px">
      <SkeletonText noOfLines={2} spacing="4" skeletonHeight="6" mb="10px" />
      <Flex h="full" w="full" gap="30px" mb="30px">
        <Skeleton w="100%" h="70px" borderRadius="10px" />
        <Skeleton w="100%" h="70px" borderRadius="10px" />
        <Skeleton w="100%" h="70px" borderRadius="10px" />
      </Flex>
      <Skeleton w="100%" h="70px" borderRadius="10px" />
    </Flex>
  );
};

const ThreeColumnCard = ({ contentType, page }) => {
  const { getThreeColumnCard } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getThreeColumnCard(page).then((response) => response),
    refetchOnMount: false,
  });

  const backgroundColor =
    data && !isFetching ? `#${data["backgroundColour"]}` : "#E5E7EB";
  const isLoading = isFetching || !data;

  return (
    <PageContainer mt="0" mb="0">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex
          w="full"
          flexDirection="column"
          borderRadius="10px"
          bg={backgroundColor}
          p={{ base: "20px", md: "50px" }}
        >
          <PageContainerTitle w="full" textAlign="left" mb="30px">
            {data && documentToReactComponents(data["headline"])}
          </PageContainerTitle>

          <PageContainerDescription w="full" textAlign="left" mb="40px">
            {data && documentToReactComponents(data["subHeadline"])}
          </PageContainerDescription>

          <Grid3Columns
            mt="0"
            mb="20px"
            templateColumns={{ base: "repeat(1)", md: "repeat(3, 1fr)" }}
          >
            {data &&
              data.items.content[0].content.map((content, index) => (
                <Grid3Item key={`three-columncard-${index}`} gridAutoRows="1fr">
                  <Card
                    bg="white"
                    flexDirection="row"
                    p="20px"
                    minW={{ base: "full", md: "auto" }}
                    mb={{ base: "10px", md: "25px" }}

                  >
                    <Center
                      minW="48px"
                      minH="48px"
                      maxW="48px"
                      maxH="48px"
                      mr="20px"
                      borderRadius={"50%"}
                      bg="bg.greenPrimary"
                    >
                      <Image src={ArrowUpIcon} w="28px" h="28px" alt="arrow" />
                    </Center>

                    <PageContainerDescription
                      w="full"
                      textAlign="left"
                      fontSize="16px"
                    >
                      {documentToReactComponents(content, OPTIONS)}
                    </PageContainerDescription>
                  </Card>
                </Grid3Item>
              ))}
          </Grid3Columns>

          <PageContainerDescription w="full" justifyContent="center">
            {data && documentToReactComponents(data["callout"])}
          </PageContainerDescription>
        </Flex>
      )}
    </PageContainer>
  );
};

export default ThreeColumnCard;

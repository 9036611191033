import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Flex, SkeletonText } from "@chakra-ui/react";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
} from "src/components/Common/Containers";
import useContentful from "src/hooks/useContentful";

const OPTIONS = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul style={{ lineHeight: "25px", textAlign: "left", marginLeft: "20px" }}>
        {children}
      </ul>
    ),
  },
};

const PageLoader = () => {
  return (
    <Flex flexDirection="column" w="full" gap="50px">
      <SkeletonText noOfLines={4} spacing="4" skeletonHeight="2" mb="20px" />
      <SkeletonText noOfLines={4} spacing="4" skeletonHeight="2" mb="20px" />
      <SkeletonText noOfLines={4} spacing="4" skeletonHeight="2" mb="20px" />
      <SkeletonText noOfLines={4} spacing="4" skeletonHeight="2" mb="20px" />
    </Flex>
  );
};

const ContentBlock = ({ page, contentType }) => {
  const { getContentBlock } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getContentBlock(page).then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;

  return (
    <PageContainer mt="0" mb="100px" pt={{ base: "50px", md: "120px" }}>
      {isLoading ? (
        <PageLoader mt="100px" />
      ) : (
        <Flex flexDirection="column">
          <PageContainerTitle
            w="full"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            fontSize="22px"
            fontWeight={400}
            lineHeight="32px"
            textAlign="left"
            mb="20px"
          >
            {data && documentToReactComponents(data["headline"], OPTIONS)}
          </PageContainerTitle>

          <PageContainerDescription
            flexDirection="column"
            alignItems="flex-start"
            w="full"
            textAlign="left"
            fontSize="14px"
            lineHeight="20px"
            fontWeight={400}
            gap="30px"
          >
            {data && documentToReactComponents(data["body"], OPTIONS)}
          </PageContainerDescription>
        </Flex>
      )}
    </PageContainer>
  );
};

export default ContentBlock;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Flex, Image, Box, Skeleton } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import Button from "src/components/Common/Button";
import { ArrowRightIcon } from "src/assets";

const PageLoader = () => {
  return (
    <Skeleton
      w="100%"
      h="100px"
      borderRadius="10px"
      endColor="bg.secondary"
      startColor="bg.primary"
    />
  );
};

const CTACallout = ({ contentType, page }) => {
  const { getCTACallout } = useContentful();

  const { data: ctaCallout, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getCTACallout(page).then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !ctaCallout;
  const ctaLink =
    ctaCallout && ctaCallout["ctaTarget"] ? ctaCallout["ctaTarget"] : "";

  return (
    <Flex
      minH="239px"
      bg="bg.primary"
      px={{ base: "20px", md: "170px" }}
      flexDirection={{ base: "column", md: "row" }}
      justifyContent={{ base: "center", md: "space-between" }}
      gap={{ base: "20px", md: "0" }}
      alignItems="center"
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <Box fontSize="26px" fontWeight={500} lineHeight="32px" color="white">
            {ctaCallout && documentToReactComponents(ctaCallout["headline"])}
          </Box>

          <Button
            mr={4}
            bg="bg.tealPrimary"
            color="bg.primary"
            rightIcon={<Image src={ArrowRightIcon} alt="Arrow Icon" />}
            targetUrl={ctaLink}
          >
            {ctaCallout && ctaCallout["ctaButton"]}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default CTACallout;

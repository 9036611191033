import { useNavigate } from "react-router-dom";

export const useRedirect = () => {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    if (url && url.startsWith("https")) {
      window.open(url, "_blank");
    } else {
      navigate(url, { replace: true });

      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, 500);
    }
  };

  return {
    handleNavigate,
  };
};

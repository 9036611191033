import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";

const FAQ = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "faqPageTitle", description: "faqMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="faqHeader" page="FAQ" />

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="faqFeaturedFAQ" page="FAQ" mt="0" />
    </>
  );
};

export default FAQ;

import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Flex,
  Center,
  Skeleton,
} from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import {
  PageContainer,
  PageContainerDescription,
} from "src/components/Common/Containers";
import { CheckIcon } from "src/assets";
import Car from "src/assets/images/Car.png";

const PageLoader = () => {
  return <Skeleton w="full" h="250px" borderRadius="10px" />;
};

const FeaturedProducts = () => {
  const { getHomepageFeaturedProduct } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: ["homepageFeatureProducts"],
    queryFn: () => getHomepageFeaturedProduct().then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;

  return (
    <PageContainer pt="0" mt="0" mb="100px">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid
          w="full"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
          mt="80px"
          gap="30px"
        >
          {data &&
            data.map(
              ({ fields: { productName, productImage, target } }, index) => (
                <GridItem
                  w="full"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  key={`grid-item-section8-${index}`}
                  cursor="pointer"
                  _hover={{
                    ".productItemHover": {
                      boxShadow:
                        "0px -2px 8px rgba(0, 0, 0, 0.04), 0px 6px 10px rgba(115, 117, 118, 0.2)",
                    },
                  }}
                  onClick={() => {
                    if (target) {
                      window.open(target, "_self");
                    }
                  }}
                >
                  <Box>
                    <Image
                      src={productImage?.fields.file.url || Car}
                      alt="Car Icon"
                    />
                  </Box>

                  <Flex
                    borderRadius="20px"
                    h="40px"
                    bg="bg.bluePrimary"
                    w="full"
                    alignItems="center"
                    transition={"0.5s ease"}
                    className="productItemHover"
                  >
                    <Center
                      bg="bg.greenPrimary"
                      borderRadius="50%"
                      h="36px"
                      w="36px"
                      mr="16px"
                    >
                      <Image src={CheckIcon} alt="Car Icon" />
                    </Center>
                    <PageContainerDescription>
                      {productName}
                    </PageContainerDescription>
                  </Flex>
                </GridItem>
              )
            )}
        </Grid>
      )}
    </PageContainer>
  );
};

export default FeaturedProducts;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
  Grid3Columns,
  Grid3Item,
  Card,
} from "src/components/Common/Containers";

const OPTIONS = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => <span>{children}</span>,
  },
};

const PageLoader = () => {
  return (
    <Flex flexDirection="column" w="full" gap="50px">
      <SkeletonText noOfLines={2} spacing="4" skeletonHeight="6" mb="10px" />
      <Flex h="full" w="full" gap="30px" mb="30px">
        <Skeleton w="100%" h="70px" borderRadius="10px" />
        <Skeleton w="100%" h="70px" borderRadius="10px" />
        <Skeleton w="100%" h="70px" borderRadius="10px" />
      </Flex>
      <Skeleton w="100%" h="70px" borderRadius="10px" />
    </Flex>
  );
};

const FinanceCategory = ({ contentType, page }) => {
  const { getFinanceCategories } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getFinanceCategories(page).then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;

  return (
    <PageContainer mt="0" mb="0">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex
          w="full"
          flexDirection="column"
          borderRadius="10px"
          p={{ base: "20px", md: "50px" }}
        >
          {data && data["headline"] && (
            <PageContainerTitle w="full" textAlign="left" mb="30px">
              {data && data["headline"]}
            </PageContainerTitle>
          )}

          <Grid3Columns
            mt="0"
            mb="50px"
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          >
            {data &&
              data.categories.content[0].content.map((content, index) => (
                <Grid3Item key={`three-columncard-${index}`}>
                  <Card
                    minW={{ base: "full", md: "210px" }}
                    p="20px"
                    mb={{ base: "0", md: "20px" }}
                    boxShadow="none"
                    border="2px solid #E5E7EB"
                    transition="0.2s ease-in-out"
                    _hover={{ bg: "#f2f2f2" }}
                  >
                    <PageContainerDescription
                      w="full"
                      fontSize="22px"
                      fontWeight={500}
                      color="bg.greenPrimary"
                      justifyContent="center"
                    >
                      {documentToReactComponents(content, OPTIONS)}
                    </PageContainerDescription>
                  </Card>
                </Grid3Item>
              ))}
          </Grid3Columns>

          {data && data["callout"] && (
            <PageContainerDescription w="full" justifyContent="center">
              {data && documentToReactComponents(data["callout"])}
            </PageContainerDescription>
          )}
        </Flex>
      )}
    </PageContainer>
  );
};

export default FinanceCategory;

import { Flex, Skeleton, SkeletonText } from "@chakra-ui/react";

const HeaderLoader = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={2}
    >
      <Skeleton
        w={{ base: "90%", md: "50%" }}
        h="150px"
        borderRadius="10px"
        mb="50px"
      />
      <SkeletonText
        w={{ base: "90%", md: "50%" }}
        noOfLines={2}
        spacing="4"
        skeletonHeight="5"
        mb="50px"
      />
      <Skeleton
        w={{ base: "60%", md: "20%" }}
        h="50px"
        borderRadius="10px"
        mb="50px"
      />
      <Skeleton
        w={{ base: "90%", md: "50%" }}
        h="50px"
        borderRadius="10px"
        mb="50px"
      />
    </Flex>
  );
};

export default HeaderLoader;

import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ContentBlock from "src/components/Common/ContentBlock";

const FeesAndCharges = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "feesChargesPageTitle", description: "feesChargesMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="fees&ChargesHeader" page="Fees & Charges" />

      {/* Content Type: Content Block */}
      <ContentBlock contentType="fees&ChargesContent" page="Fees & Charges" />
    </>
  );
};

export default FeesAndCharges;

import { Outlet } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import Navigation from "../Navigation";
import Footer from "../Footer";

export const Layout = () => (
  <Flex textAlign="center" w="full" flexDirection="column" fontSize="xl">
    <Flex flexDirection="column" minH="839px">
      <Navigation />
      <Outlet />
      <Footer />
    </Flex>
  </Flex>
);

import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import TwoColumnFeature from "src/components/Common/TwoColumnFeature";
import ContentBlock from "./ContentBlock";
import ThreeColumnSellingPoints from "src/components/Common/ThreeColumnSellingPoints";
import StatsCallout from "src/components/Common/StatsCallout";

const About = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "aboutPageTitle", description: "aboutMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="aboutHeader" page="About" />

      {/* Content Type: Content Block */}
      <ContentBlock contentType="aboutUsContent" page="About Us" />

      {/* Content Type: Two Column Featur */}
      <TwoColumnFeature
        column="About: Two Column Feature 1"
        contentType="aboutTwoColumn"
        page="About"
      />
      <TwoColumnFeature
        column="About: Two Column Feature 2"
        contentType="aboutTwoColumn"
        page="About"
      />

      {/* Content Type: Three Column Selling Points */}
      {/* <ThreeColumnSellingPoints
        contentType="aboutTCSellingPoints"
        page="About"
        pt="50px"
        mb="100px"
      /> */}

      {/* Content Type: Two Column Featur */}
      <TwoColumnFeature
        column="About: Two Column Feature 3"
        contentType="aboutTwoColumn"
        page="About"
      />

      {/* Content Type: Stats Callout */}
      <StatsCallout
        contentType="aboutStatsCallout"
        page="About Us"
      />

      {/* Content Type: Two Column Featur */}
      <TwoColumnFeature
        column="About: Two Column Feature 4"
        contentType="aboutTwoColumn"
        page="About"
      />
      <TwoColumnFeature
        column="About: Two Column Feature 5"
        contentType="aboutTwoColumn"
        page="About"
      />
    </>
  );
};

export default About;

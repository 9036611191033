import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ThreeColumnSellingPoints from "src/components/Common/ThreeColumnSellingPoints";
import ReviewCard from "src/components/Common/ReviewCard";
import ThreeColumnFeature from "src/components/Common/ThreeColumnFeature";
import CTACallout from "src/components/Common/CTACallout";
import SimpleCTA from "src/components/Common/SimpleCTA";
import ThreeColumnCard from "../common/ThreeColumnCard";
import HeroCard from "src/components/Common/HeroCard";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";

const CarFinance = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "carPageTitle", description: "carMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="carFinanceHeader" page="Car Finance" />

      {/* Content Type: Three Column Feature */}
      <ThreeColumnFeature contentType="carFinanceTCF" page="Car Finance" />

      {/* Content Type: CTA Callout */}
      <CTACallout contentType="carCTACallout" page="Homepage" />

      {/* Content Type: Three Column Selling Points */}
      <ThreeColumnSellingPoints
        contentType="carFinanceTCSellingPoints"
        page="Car Finance"
      />

      {/* Content Type: Simple CTA */}
      <SimpleCTA contentType="carFinanceSimpleCTA" page="Car Finance" />

      {/* Content Type: Three Column Card */}
      <ThreeColumnCard contentType="carFinanceTCCard" page="Car Finance" />

      {/* Content Type: Two Column Feature */}
      <HeroCard
        contentType="carFinanceTwoColumnFeature"
        page="Car Finance"
        name="Car Finance: Two Column Feature"
      />

      {/* Content Type: Featured Review Card */}
      <ReviewCard contentType="carFinanceReviewCard" page="Car Finance" />

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="carFinanceFeaturedFAQ" page="Car Finance" />
    </>
  );
};

export default CarFinance;

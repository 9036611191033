import { useQuery } from "@tanstack/react-query";
import { MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Flex, Image, Box, SkeletonText } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import Button from "src/components/Common/Button";
import { PageContainer } from "src/components/Common/Containers";
import { ArrowRightIcon } from "src/assets";

const OPTIONS = {
  renderMark: {
    [MARKS.UNDERLINE]: (text) => (
      <span style={{ textDecoration: "underline", color: "#5CE555" }}>
        {text}
      </span>
    ),
  },
};

const Callout = () => {
  const { getHeroCallout, getCTACallout } = useContentful();

  const { data: heroCallout, isFetching: calloutIsFetching } = useQuery({
    queryKey: ["homepageHeroCallout"],
    queryFn: () => getHeroCallout("Homepage").then((response) => response),
    refetchOnMount: false,
  });

  const { data: ctaCallout, isFetching: ctaCalloutIsFetching } = useQuery({
    queryKey: ["homepageCTACallout"],
    queryFn: () => getCTACallout("Homepage").then((response) => response),
    refetchOnMount: false,
  });

  const isLoading =
    calloutIsFetching || ctaCalloutIsFetching || !heroCallout || !ctaCallout;
    const ctaLink = (ctaCallout && ctaCallout["ctaTarget"]) ? ctaCallout["ctaTarget"] : "";

  return (
    <>
      <PageContainer mb="150px" mt="50px">
        {isLoading ? (
          <SkeletonText
            noOfLines={2}
            w="full"
            spacing="4"
            skeletonHeight="10"
          />
        ) : (
          <>
            <Box
              fontSize={{ base: "26px", md: "54px" }}
              fontWeight={500}
              lineHeight={{ base: "40px", md: "60px" }}
              color="bg.primary"
            >
              {heroCallout &&
                documentToReactComponents(heroCallout["headline"], OPTIONS)}
            </Box>
          </>
        )}
      </PageContainer>

      <Flex
        minH="239px"
        bg="bg.primary"
        px={{ base: "20px", md: "170px" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
        gap={{ base: "20px", md: "0" }}
        alignItems="center"
      >
        {isLoading ? (
          <SkeletonText
            noOfLines={2}
            w="full"
            spacing="4"
            skeletonHeight="10"
            endColor="bg.secondary"
            startColor="bg.primary"
          />
        ) : (
          <>
            {" "}
            <Box
              fontSize="26px"
              fontWeight={500}
              lineHeight="32px"
              color="white"
            >
              {ctaCallout && documentToReactComponents(ctaCallout["headline"])}
            </Box>
            <Button
              mr={4}
              bg="bg.tealPrimary"
              color="bg.primary"
              targetUrl={ctaLink}
              rightIcon={<Image src={ArrowRightIcon} alt="Arrow Icon" />}
            >
              {ctaCallout && ctaCallout["ctaButton"]}
            </Button>
          </>
        )}
      </Flex>
    </>
  );
};

export default Callout;

import { useRedirect } from "src/hooks/useRedirect";
import { Button as ChakraButton, Text } from "@chakra-ui/react";
const Button = ({
  children,
  type,
  targetUrl,
  bg = "bg.primary",
  onClick = () => {},
  ...rest
}) => {
  const { handleNavigate } = useRedirect();

  if (type === "Link") {
    return (
      <Text
        color="bg.primary"
        textDecoration="underline"
        onClick={() => handleNavigate(targetUrl)}
      >
        {children}
      </Text>
    );
  }

  if (targetUrl) {
    return (
      <ChakraButton
        bg={bg}
        minW="fit-content"
        transition="0.5s ease"
        _hover={{
          bg,
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
        }}
        onClick={() => handleNavigate(targetUrl)}
        {...rest}
      >
        {children}
      </ChakraButton>
    );
  }

  return (
    <ChakraButton
      bg={bg}
      minW="fit-content"
      transition="0.5s ease"
      _hover={{
        bg,
        boxShadow:
          "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  Box,
  Image,
  Flex,
  Skeleton,
  SkeletonText,
  useMediaQuery,
} from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import Button from "src/components/Common/Button";
import {
  PageContainer,
  PageContainerDescription,
  PageContainerTitle,
} from "src/components/Common/Containers";
import { ArrowRightIcon } from "src/assets";
import Union from "src/assets/images/Union.png";

const PageLoader = () => {
  return (
    <>
      <Flex w="full" flexDirection={{ base: "column", md: "row" }} gap="50px">
        <Flex
          w="full"
          flexDirection="column"
          gap={{ base: "30px", md: "50px" }}
        >
          <SkeletonText
            noOfLines={2}
            w="full"
            spacing="4"
            skeletonHeight="5"
            endColor="bg.secondary"
            startColor="bg.primary"
          />
          <SkeletonText
            noOfLines={2}
            w="full"
            spacing="4"
            skeletonHeight="5"
            endColor="bg.secondary"
            startColor="bg.primary"
          />
          <SkeletonText
            noOfLines={2}
            w="full"
            spacing="4"
            skeletonHeight="5"
            endColor="bg.secondary"
            startColor="bg.primary"
          />
        </Flex>

        <Skeleton
          w="full"
          h="250px"
          borderRadius="10px"
          endColor="bg.secondary"
          startColor="bg.primary"
        />
      </Flex>
    </>
  );
};

const HeroCard = () => {
  const { getHomepageHeroCard } = useContentful();
  const [isLessThan1200] = useMediaQuery("(max-width: 1200px)");

  const { data, isFetching } = useQuery({
    queryKey: ["homepageHeroCard"],
    queryFn: () => getHomepageHeroCard().then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;
  const bannerImage = data && data["banner"]?.fields.file.url;
  const ctaLink = data && data["ctaTarget"] ? data["ctaTarget"] : "";

  return (
    <PageContainer
      pt="0"
      mt="0"
      mb="100px"
      bg="bg.primary"
      borderRadius="10px"
      mx={{ base: "20px", md: isLessThan1200 ? "50px" : "170px" }}
      p={{ base: "40px", md: "61px 64px 44px 40px" }}
      justifyContent="space-between"
      flexDirection={{ base: "column", md: "row" }}
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            mb={{ base: "50px", md: "0" }}
          >
            <PageContainerTitle color="white" textAlign="left">
              {data && documentToReactComponents(data["headline1"])}
            </PageContainerTitle>

            <PageContainerTitle
              color="bg.greenPrimary"
              mb="25px"
              textAlign="left"
            >
              {data && documentToReactComponents(data["headline2"])}
            </PageContainerTitle>

            <PageContainerDescription mb="25px" color="white" textAlign="left">
              {data && documentToReactComponents(data["subHeadline"])}
            </PageContainerDescription>

            <Button
              mr={4}
              color="bg.primary"
              bg="white"
              rightIcon={<Image src={ArrowRightIcon} alt="Arrow Icon" />}
              targetUrl={ctaLink}
            >
              {data && data["ctaButton"]}
            </Button>
          </Flex>

          <Box minW="243px" minH="243px" ml={{ base: "0", md: "90px" }}>
            <Image src={bannerImage || Union} alt="Car Icon" />
          </Box>
        </>
      )}
    </PageContainer>
  );
};

export default HeroCard;

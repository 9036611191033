import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "src/hooks/useContentful";
import {
  Box,
  Grid,
  GridItem,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
  Image,
  Flex,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import {
  PageContainer,
  PageContainerTitle,
} from "src/components/Common/Containers";
import { ArrowDownIcon, SignatureIcon } from "src/assets";
import Image1 from "src/assets/images/Image1.png";

const indicatorTheme = {
  "[data-status=complete] &": {
    background: "bg.greenPrimary",
    borderColor: "bg.greenPrimary",
  },
  "[data-status=active] &": {
    background: "bg.greyPrimary",
    borderColor: "bg.greyPrimary",
  },
  "[data-status=incomplete] &": {
    background: "bg.primary",
    borderColor: "bg.primary",
  },
};

export const PageLoader = ({ ...rest }) => {
  return (
    <>
      <Skeleton w="full" h="40px" borderRadius="10px" mb="40px" {...rest} />
      <Flex w="full" flexDirection={{ base: "column", md: "row" }} gap="50px">
        <Flex
          w="full"
          flexDirection="column"
          gap={{ base: "30px", md: "50px" }}
        >
          <SkeletonText noOfLines={2} w="full" spacing="4" skeletonHeight="5" />
          <SkeletonText noOfLines={2} w="full" spacing="4" skeletonHeight="5" />
          <SkeletonText noOfLines={2} w="full" spacing="4" skeletonHeight="5" />
        </Flex>

        <Skeleton w="full" h="250px" borderRadius="10px" />
      </Flex>
    </>
  );
};

const SteppedFeature = () => {
  const { getHomepageSteppedFeature } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: ["homepageSteppedFeature"],
    queryFn: () => getHomepageSteppedFeature().then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;

  const heroImage = data && data["heroImage"].fields.file.url;

  return (
    <PageContainer>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageContainerTitle>
            {data && documentToReactComponents(data["Headline"])}
          </PageContainerTitle>

          <Grid
            w="full"
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            mt="80px"
            gap="30px"
          >
            <GridItem w="full">
              <Stepper
                size="sm"
                index={1}
                orientation="vertical"
                h={{ base: "auto", md: "375px" }}
                gap="0"
              >
                {data &&
                  data["steps"].map(({ fields }, index) => (
                    <Step key={index} textAlign="left">
                      <StepIndicator mr="20px" sx={indicatorTheme}>
                        <StepStatus
                          incomplete={
                            <Image src={ArrowDownIcon} alt="Arrow Down" />
                          }
                        />
                      </StepIndicator>

                      <Box
                        color="bg.primary"
                        mt="-7px"
                        mb={{ base: "25px", md: "0px" }}
                      >
                        <Box fontSize="26px" fontWeight={500}>
                          {documentToReactComponents(fields["headline"])}
                        </Box>
                        <Box fontSize="16px" fontWeight={500}>
                          {documentToReactComponents(fields["subHeadline"])}
                        </Box>
                      </Box>

                      <StepSeparator
                        maxH="full !important"
                        w="3px !important"
                        top="0 !important"
                        _vertical={{
                          backgroundColor:
                            index < 1 ? "bg.greenPrimary" : "bg.greyPrimary",
                        }}
                      />
                    </Step>
                  ))}
              </Stepper>

              <Flex w="100px" mt={{ base: "0", md: "20px" }}>
                <Image
                  src={SignatureIcon}
                  w="62px"
                  h="62px"
                  ml="50px"
                  alt="Signature Icon"
                />
              </Flex>
            </GridItem>

            <GridItem
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={heroImage || Image1} alt="Menu Icon" maxH="468px" />
            </GridItem>
          </Grid>
        </>
      )}
    </PageContainer>
  );
};

export default SteppedFeature;

import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";
import { useContext } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Flex,
  Text,
  Center,
} from "@chakra-ui/react";
import { AppContext } from "src/providers/AppProvider";
import { RedirectToIcon } from "src/assets";

const NavLink = ({ route, subPages, handleNavigate, children }) => {
  const { pathname } = useLocation();
  const { navigationSettings } = useContext(AppContext);
  const [isMobileView] = useMediaQuery("(max-width: 766px)");
  const offsetAmount = isMobileView ? 78 : 132;

  if (!subPages) {
    return (
      <Flex
        px={2}
        py={1}
        color="bg.primary"
        fontWeight={500}
        fontSize="20px"
        borderRadius="6px"
        _hover={{ bg: "#FFFFFF" }}
        transition="0.5s ease"
        textAlign="left"
        cursor="pointer"
        onClick={() => handleNavigate(route)}
      >
        {children}
      </Flex>
    );
  }

  return (
    <Menu>
      <MenuButton
        px={2}
        py={1}
        color="bg.primary"
        fontWeight={500}
        fontSize="20px"
        borderRadius="6px"
        _hover={{ bg: "#FFFFFF" }}
        transition="0.5s ease"
        textAlign="left"
      >
        {children}
      </MenuButton>

      {subPages && (
        <MenuList p="10px" mt="15px">
          {subPages.map(({ text, route, icon }) => (
            <MenuItem
              key={text}
              offset={offsetAmount}
              bg={pathname.includes(route) ? "#f2f2f2" : "bg.white"}
              transition="0.5s ease"
              _hover={{ bg: "#f2f2f2" }}
              onClick={() => handleNavigate(route)}
            >
              <Flex alignItems="center">
                <Center
                  bg="bg.bluePrimary"
                  borderRadius="6px"
                  w="50px"
                  h="50px"
                  mr="20px"
                  _hover={{ bg: "none" }}
                >
                  <Image
                    src={
                      navigationSettings && navigationSettings[icon]
                        ? navigationSettings[icon].fields.file.url
                        : RedirectToIcon
                    }
                  />
                </Center>
                <Text color="#000" fontSize={"16px"}>
                  {text}
                </Text>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  );
};

export default NavLink;

import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Flex, Image, Skeleton, SkeletonText } from "@chakra-ui/react";
import useContentful from "src/hooks/useContentful";
import Button from "src/components/Common/Button";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
} from "src/components/Common/Containers";
import { ArrowRightTealIcon } from "src/assets";

const PageLoader = () => {
  return (
    <Flex flexDirection="column" w="full" gap="50px">
      <SkeletonText noOfLines={2} spacing="4" skeletonHeight="6" mb="10px" />
      <Skeleton w={{ base: "60%", md: "20%" }} h="70px" borderRadius="10px" />
    </Flex>
  );
};

const SimpleCTA = ({ contentType, page }) => {
  const { getSimpleCTA } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: [contentType],
    queryFn: () => getSimpleCTA(page).then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;
  const ctaLink = data && data["ctaTarget"] ? data["ctaTarget"] : "";

  return (
    <PageContainer mt="0" mb="0">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex w="full" flexDirection="column" alignItems="flex-start">
          <PageContainerTitle w="full" textAlign="left" mb="40px">
            {data && documentToReactComponents(data["headline"])}
          </PageContainerTitle>

          <PageContainerDescription w="full" textAlign="left" mb="40px">
            {data && documentToReactComponents(data["subHeadline"])}
          </PageContainerDescription>

          <Button
            bg="bg.primary"
            color="bg.tealPrimary"
            rightIcon={<Image src={ArrowRightTealIcon} alt="Arrow Icon" />}
            targetUrl={ctaLink}
          >
            {data && data["ctaText"]}
          </Button>
        </Flex>
      )}
    </PageContainer>
  );
};

export default SimpleCTA;

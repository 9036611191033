import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE || "",
  environment: process.env.REACT_APP_CONTENTFUL_ENV || "",
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN || "",
});

const getEntries = async (contentType, fields = {}) => {
  try {
    const entries = await client.getEntries({
      content_type: contentType,
      ...fields,
    });

    return entries.items[0].fields;
  } catch (error) {
    console.log(`Error fetching homepage ${error}`);
  }
};

const getMultipleEntries = async (contentType, fields = {}) => {
  try {
    const entries = await client.getEntries({
      content_type: contentType,
      ...fields,
    });

    return entries.items;
  } catch (error) {
    console.log(`Error fetching homepage ${error}`);
  }
};

const useContentful = () => {
  return {
    getNavigationSettings: () => getEntries("navigation"),
    getHomepageEntry: (page) => getEntries("header", { "fields.page": page }),
    getHomepageThreeColumn: (page) =>
      getEntries("threeColumnFeature", { "fields.page": page }),
    getHomepageSteppedFeature: () => getEntries("twoColumnSteppedFeature"),
    getHeroCallout: (page) => getEntries("heroCallout", { "fields.page": page }),
    getCTACallout: (page) => getEntries("ctaCallout", { "fields.page": page }),
    getTwoColumnMultiple: (page) =>
      getMultipleEntries("twoColumnFeature", { "fields.page": page }),
    getHomepageFeaturedProduct: () =>
      getMultipleEntries("featuredProductCategories"),
    getHomepageHeroCard: () => getEntries("heroCard"),
    getHomepageLogoCallout: () => getEntries("logoCallout"),
    getHomepageFeaturedArticle: () => getEntries("featuredArticle"),
    getFeaturedFAQ: (page) => getEntries("featuredFaq", { "fields.page": page }),
    getThreeColumnSellingPoints: (page) =>
      getEntries("threeColumnSellingPoints", { "fields.page": page }),
    getSimpleCTA: (page) => getEntries("simpleCta", { "fields.page": page }),
    getThreeColumnCard: (page) =>
      getEntries("threeColumnCard", { "fields.page": page }),
    getTwoColumnFeature: (page, name) =>
      getEntries("twoColumnFeature", {
        "fields.page": page,
        "fields.internalName": name,
      }),
    getReviewCard: (page) => getEntries("featuredReviewCard", { "fields.page": page }),
    getFinanceCategories: (page) => getEntries("financeCategory", { "fields.page": page }),
    getContentBlock: (page) => getEntries("contentBlock", { "fields.page": page }),
    getStatsCallout: (page) => getEntries("statsCallout", { "fields.page": page }),
  };
};

export default useContentful;

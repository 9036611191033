import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ThreeColumnSellingPoints from "src/components/Common/ThreeColumnSellingPoints";
import ReviewCard from "src/components/Common/ReviewCard";
import ThreeColumnFeature from "src/components/Common/ThreeColumnFeature";
import CTACallout from "src/components/Common/CTACallout";
import SimpleCTA from "src/components/Common/SimpleCTA";
import ThreeColumnCard from "../common/ThreeColumnCard";
import HeroCard from "src/components/Common/HeroCard";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";

const CaravanFinance = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "caravanPageTitle", description: "caravanMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="caravanFinanceHeader" page="Caravan Finance" />

      {/* Content Type: Three Column Feature */}
      <ThreeColumnFeature contentType="caravanFinanceTCF" page="Caravan Finance" />

      {/* Content Type: CTA Callout */}
      <CTACallout contentType="caravanCTACallout" page="Caravan Finance" />

      {/* Content Type: Three Column Selling Points */}
      <ThreeColumnSellingPoints contentType="caravanFinanceTCSellingPoints" page="Caravan Finance" />

      {/* Content Type: Three Column Selling Points */}
      <SimpleCTA contentType="caravanFinanceSimpleCTA" page="Caravan Finance" />

      {/* Content Type: Three Column Card */}
      <ThreeColumnCard contentType="caravanFinanceTCCard" page="Caravan Finance" />

      {/* Content Type: Two Column Feature */}
      <HeroCard
        contentType="caravanFinanceTwoColumnFeature"
        page="Caravan Finance"
        name="Caravan Finance: Two Column Feature"
      />

      {/* Content Type: Featured Review Card */}
      <ReviewCard contentType="caravanFinanceReviewCard" page="Caravan Finance" />

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="caravanFinanceFeaturedFAQ" page="Caravan Finance" />
    </>
  );
};

export default CaravanFinance;

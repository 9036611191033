import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Flex, HStack, useDisclosure, Collapse } from "@chakra-ui/react";
import Button from "src/components/Common/Button";
import { pages, APPLY_ONLINE_URL } from "src/utils/constant";
import { ThemeContext } from "src/providers/ThemeProvider";
import { useRedirect } from "src/hooks/useRedirect";
import CompanyLogo from "./Logo";
import NavLink from "./NavLink";
import NavLinkMobile from "./NavLinkMobile.js";

const Navigation = () => {
  const { handleNavigate } = useRedirect();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { headerBackground } = useContext(ThemeContext);

  const [withBG, setWithBG] = useState(false);

  const handleNavigateToPage = (url) => {
    handleNavigate(url);
    onClose();
  };

  useEffect(() => {
    // adding the event when scroll change background
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        setWithBG(true);
      } else {
        setWithBG(false);
      }
    });
  }, []);

  return (
    <Box
      id="header"
      px={{ base: "20px", lg: "80px" }}
      position="fixed"
      top="0"
      zIndex={9999}
      w="full"
      transition="0.4s ease"
      bg={withBG || isOpen ? "#FFFFFF" : headerBackground}
      boxShadow={withBG ? "0 6px 6px -2px rgba(0, 0, 0, 0.2)" : "none"}
    >
      <CompanyLogo isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

      <Flex
        h={16}
        mb="10px"
        alignItems={"center"}
        display={{ base: "none", md: "flex" }}
        justifyContent={"space-between"}
      >
        <HStack spacing={8} alignItems={"center"}>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {pages.map(({ text, route, subPages }) => (
              <NavLink
                route={route}
                key={route}
                subPages={subPages}
                handleNavigate={handleNavigateToPage}
              >
                {text}
              </NavLink>
            ))}
          </HStack>
        </HStack>

        <Flex alignItems={"center"}>
          <Link
            to={APPLY_ONLINE_URL}
            target="_blank"
            rel="noopener noreferrer"
            style={{ alignSelf: "flex-start" }}
          >
            <Button mr={4} bg="bg.primary" color="text.white">
              Apply Online
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box pb={4} display={{ md: "none" }} bg="white">
          <NavLinkMobile handleNavigate={handleNavigateToPage} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default Navigation;

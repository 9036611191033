import { Flex, Image } from "@chakra-ui/react";
import { useRedirect } from "src/hooks/useRedirect";
import Button from "src/components/Common/Button";
import { PageContainerDescription, PageContainerTitle } from "./Containers";
import { ArrowRightTealIcon } from "src/assets";
import NotFoundImage from "src/assets/images/404-page-not-found.png";

export const NotFound = () => {
  const { handleNavigate } = useRedirect();

  return (
    <Flex
      w="full"
      h="100svh"
      flexDirection={{ base: "column", md: "row" }}
      alignItems="center"
      justifyContent="center"
      p={{ base: "20px", md: "100px" }}
      gap={{ base: "30px", md: "100px" }}
    >
      <Flex flexDirection={"column"} gap="30px" maxW="500px">
        <PageContainerTitle>
          Oops! The page you were looking for doesn't exist
        </PageContainerTitle>
        <PageContainerDescription>
          You may have mistyped the address or the page may have moved
        </PageContainerDescription>
        <Button
          mr={4}
          color="bg.tealPrimary"
          bg="bg.primary"
          rightIcon={<Image src={ArrowRightTealIcon} alt="Arrow Icon" />}
          maxW={{ base: "full", md: "200px" }}
          onClick={() => handleNavigate("/")}
        >
          Back to Home
        </Button>
      </Flex>
      <Flex>
        <Image src={NotFoundImage} alt="Arrow Icon" maxW="500px" />
      </Flex>
    </Flex>
  );
};

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Layout } from "src/components/Common/Layout";
import Homepage from "src/components/Homepage";
import BoatFinance from "src/components/Finance/BoatFinance";
import CarFinance from "src/components/Finance/CarFinance";
import CaravanFinance from "src/components/Finance/CaravanFinance";
import BusinessFinance from "src/components/Finance/BusinessFinance";
import MotorbikeFinance from "src/components/Finance/MotorbikeFinance";
import LoanExperts from "src/components/LoanExperts";
import About from "src/components/About";
import PrivacyPolicy from "src/components/About/PrivacyPolicy";
import ContactUs from "src/components/About/ContactUs";
import FeesAndCharges from "src/components/About/FeesCharges";
import LicenseDisclosure from "src/components/About/LicenseDisclosure";
import TermsOfUse from "src/components/About/TermsOfUse";
import FAQ from "src/components/FAQ";

import { NotFound } from "src/components/Common/NotFound";

export default createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Homepage />} />
        <Route path="finance">
          <Route path="boat-finance" element={<BoatFinance />} />
          <Route path="car-finance" element={<CarFinance />} />
          <Route path="caravan-finance" element={<CaravanFinance />} />
          <Route path="business-finance" element={<BusinessFinance />} />
          <Route path="motorbike-finance" element={<MotorbikeFinance />} />
        </Route>
        <Route path="loan-experts" element={<LoanExperts />} />
        <Route path="about">
          <Route path="about-us" element={<About />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="fees-and-charges" element={<FeesAndCharges />} />
          <Route path="license-disclosure" element={<LicenseDisclosure />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
        </Route>
        <Route path="faq" element={<FAQ />} />

        <Route
          path="iamokay"
          element={<h3>Hey There!!! The App is Healthy</h3>}
        />
      </Route>

      <Route path="/*" element={<NotFound />} />
    </Route>
  )
);

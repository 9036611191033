export const getImagePosition = (position) => {
  switch (position) {
    case "Top":
      return "flex-start";
    case "Center":
      return "center";
    case "Bottom":
      return "flex-end";
    default:
      return "flex-end";
  }
};
import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ContentBlock from "src/components/Common/ContentBlock";

const TermsOfUse = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "termsOfUsePageTitle", description: "termsOfUseMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="termsOfUseHeader" page="Terms of Use" />

      {/* Content Type: Content Block */}
      <ContentBlock contentType="termsOfUseContent" page="Terms of Use" />
    </>
  );
};

export default TermsOfUse;

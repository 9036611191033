import { useContext, useEffect } from "react";
import { AppContext } from "src/providers/AppProvider";
import Headline from "src/components/Common/PageHeader";
import ThreeColumnSellingPoints from "src/components/Common/ThreeColumnSellingPoints";
import ReviewCard from "src/components/Common/ReviewCard";
import ThreeColumnFeature from "src/components/Common/ThreeColumnFeature";
import CTACallout from "src/components/Common/CTACallout";
import SimpleCTA from "src/components/Common/SimpleCTA";
import ThreeColumnCard from "../common/ThreeColumnCard";
import HeroCard from "src/components/Common/HeroCard";
import FeaturedFAQ from "src/components/Common/FeaturedFAQ";

const MotorbikeFinance = () => {
  const { setPageSettings } = useContext(AppContext);

  useEffect(() => {
    setPageSettings({ title: "motorbikePageTitle", description: "motorbikeMetaDescription" });
  }, [setPageSettings]);

  return (
    <>
      {/* Content Type: Header */}
      <Headline contentType="motorbikeFinanceHeader" page="Motorbike Finance" />

      {/* Content Type: Three Column Feature */}
      <ThreeColumnFeature contentType="motorbikeFinanceTCF" page="Motorbike Finance" />

      {/* Content Type: CTA Callout */}
      <CTACallout contentType="motorbikeFinanceCTACallout" page="Motorbike Finance" />

      {/* Content Type: Three Column Selling Points */}
      <ThreeColumnSellingPoints contentType="motorbikeFinanceTCSellingPoints" page="Motorbike Finance" />

      {/* Content Type: Three Column Selling Points */}
      <SimpleCTA contentType="motorbikeFinanceSimpleCTA" page="Motorbike Finance" />

      {/* Content Type: Three Column Card */}
      <ThreeColumnCard contentType="motorbikeFinanceTCCard" page="Motorbike Finance" />

      {/* Content Type: Two Column Feature */}
      <HeroCard
        contentType="motorbikeFinanceTwoColumnFeature"
        page="Motorbike Finance"
        name="Motorbike Finance: Two Column Feature"
      />

      {/* Content Type: Featured Review Card */}
      <ReviewCard contentType="motorbikeFinanceReviewCard" page="Motorbike Finance" />

      {/* Content Type: Featured FAQ */}
      <FeaturedFAQ contentType="motorbikeFinanceFeaturedFAQ" page="Motorbike Finance" />
    </>
  );
};

export default MotorbikeFinance;

import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  Link,
  Center,
} from "@chakra-ui/react";
import {
  PhonePrimaryIcon,
  SimplifyPrimaryLogo,
  HamburgerIcon,
  CloseIcon,
} from "src/assets";

const CompanyLogo = ({ isOpen, onClose, onOpen }) => {
  const navigate = useNavigate();

  const handleNavigateToHomepage = () => {
    navigate("/");
    onClose();
  };

  return (
    <Flex
      mt="20px"
      w="full"
      alignItems="center"
      justifyContent="space-between"
      mb={{ base: "20px", md: "0" }}
    >
      <Flex>
        <Center
          bg="none"
          mr="10px"
          cursor="pointer"
          rounded={4}
          display={{ md: "none" }}
          _hover={{ opacity: "80%" }}
          onClick={isOpen ? onClose : onOpen}
        >
          <Image src={isOpen ? CloseIcon : HamburgerIcon} alt="Menu Icon" />
        </Center>

        <Box onClick={handleNavigateToHomepage} cursor="pointer">
          <Image
            src={SimplifyPrimaryLogo}
            alt="simplify logo"
            w="132px"
            h="35px"
            ml={2}
            objectFit="cover"
          />
        </Box>
      </Flex>

      <HStack mr={4}>
        <VStack
          display={{ md: "flex" }}
          alignItems="flex-end"
          spacing="1px"
          ml="2"
        >
          <Flex>
            <Box display={{ base: "inline", md: "none", lg: "none" }}>
              <Link href="tel:0800001561" style={{ width: "100%" }}>
                <Image
                  src={PhonePrimaryIcon}
                  width="24px"
                  height="24px"
                  alt="Contact number"
                />
              </Link>
            </Box>

            <Text
              fontSize="17px"
              fontWeight={700}
              color="bg.primary"
              display={{ base: "none", md: "inline", lg: "inline" }}
            >
              0800 001 561
            </Text>
          </Flex>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default CompanyLogo;

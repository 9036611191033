import { useQuery } from "@tanstack/react-query";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Flex, Grid, GridItem, Box, Skeleton } from "@chakra-ui/react";
import {
  PageContainer,
  PageContainerTitle,
  PageContainerDescription,
} from "src/components/Common/Containers";
import useContentful from "src/hooks/useContentful";

const OPTIONS = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul style={{ listStyleType: "none", lineHeight: "35px" }}>{children}</ul>
    ),
  },
};

const Card = ({ children, ...rest }) => {
  return (
    <Flex
      minW={{ base: "full", md: "auto" }}
      p={{ base: "20px", md: "40px" }}
      justifyContent="center"
      flexDirection="column"
      borderRadius="10px"
      boxShadow="5px 10px 8px -3px rgba(0, 0, 0, 0.10);"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const PageLoader = () => {
  return (
    <>
      <Skeleton w="full" h="40px" borderRadius="10px" mb="40px" />
      <Flex w="full" gap={{ base: "10px", md: "50px" }}>
        <Skeleton
          w="full"
          h={{ base: "200px", md: "400px" }}
          borderRadius="10px"
        />
        <Skeleton
          w="full"
          h={{ base: "200px", md: "400px" }}
          borderRadius="10px"
        />
        <Skeleton
          w="full"
          h={{ base: "200px", md: "400px" }}
          borderRadius="10px"
        />
      </Flex>
    </>
  );
};

const ThreeColumnFeature = () => {
  const { getHomepageThreeColumn } = useContentful();

  const { data, isFetching } = useQuery({
    queryKey: ["homepageThreeColumns"],
    queryFn: () =>
      getHomepageThreeColumn("Homepage").then((response) => response),
    refetchOnMount: false,
  });

  const isLoading = isFetching || !data;

  const backgroundColor =
    data && !isFetching ? `#${data["backgroundColour"]}` : "#FFFFFF";

  return (
    <PageContainer mt="0" pt="80px">
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageContainerTitle>
            {data && documentToReactComponents(data["headline"])}
          </PageContainerTitle>

          <Grid
            w="full"
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, minmax(0, 1fr))" }}
            mt="80px"
            gap="30px"
            p="10px"
          >
            {data &&
              data["columns"].map(({ fields }, index) => (
                <GridItem
                  w="full"
                  flexDirection="column"
                  gridAutoRows="1fr"
                  key={`grid-item-section2-${index}`}
                  mb={{ base: "40px", md: "0px" }}
                >
                  <Card mb="25px" bg={backgroundColor} h="70%">
                    <PageContainerTitle mb="10px" textAlign="left">
                      {documentToReactComponents(fields["headline"])}
                    </PageContainerTitle>

                    <Box w="50px" minH="4px" mb="40px" bg="text.green" />

                    <PageContainerDescription textAlign="left">
                      {documentToReactComponents(
                        fields["subHeadline"],
                        OPTIONS
                      )}
                    </PageContainerDescription>
                  </Card>

                  <Card bg={backgroundColor} h="25%">
                    <Box
                      fontSize="20px"
                      lineHeight="26px"
                      fontWeight={500}
                      textAlign="center"
                      color="bg.primary"
                    >
                      {documentToReactComponents(fields["callout"])}
                    </Box>
                  </Card>
                </GridItem>
              ))}
          </Grid>
        </>
      )}
    </PageContainer>
  );
};

export default ThreeColumnFeature;

import { useNavigate } from "react-router-dom";
import { Flex, Text, SkeletonText, Grid, Image } from "@chakra-ui/react";
import { PageContainerDescription } from "src/components/Common/Containers";
import { ASSET_SELECTOR } from "src/utils/constant";

const AssetSelector = ({ isFetching }) => {
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(url, { replace: true });
  };

  return (
    <>
      <SkeletonText
        isLoaded={!isFetching}
        skeletonHeight={4}
        noOfLines={2}
        mb="50px"
      >
        <Text
          fontSize="18px"
          color="bg.primary"
          fontWeight={600}
          lineHeight="0"
        >
          What finance are you looking for?
        </Text>
      </SkeletonText>

      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
        gap={{ base: "15px", md: "30px" }}
        mt="-15px"
      >
        {ASSET_SELECTOR.map(({ text, image, route }, index) => (
          <Flex
            key={`grid-item-headline-${index}`}
            w="170px"
            h="110px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="white"
            border="2px solid #5CE555"
            borderRadius="6px"
            transition="0.4s ease"
            _hover={{ cursor: "pointer", bg: "#5CE555" }}
            onClick={() => handleNavigate(route)}
          >
            <Image src={image} alt="Car Icon" w="95px" />

            <PageContainerDescription fontSize="18px" mt="-5px" fontWeight={500}>
              {text}
            </PageContainerDescription>
          </Flex>
        ))}
      </Grid>
    </>
  );
};

export default AssetSelector;

import carAsset from "src/assets/images/asset-car.png";
import boatAsset from "src/assets/images/asset-boat.png";
import caravanAsset from "src/assets/images/asset-caravan.png";
import businessAsset from "src/assets/images/asset-business.png";

export const GRADIENT_BACKGROUND =
  "var(--Brand-Gradient, linear-gradient(160deg, #5CE555 16.5%, rgba(67, 237, 140, 0.77) 42.98%, rgba(47, 244, 185, 0.59) 55.32%, rgba(15, 255, 255, 0.30) 60.43%, rgba(15, 255, 255, 0.00) 68.37%))";

export const pages = [
  {
    text: "Finance",
    route: "#finance",
    subPages: [
      {
        text: "Car Finance",
        route: "finance/car-finance",
        icon: "carFinanceIcon",
      },
      {
        text: "Boat Finance",
        route: "finance/boat-finance",
        icon: "boatFinanceIcon",
      },
      {
        text: "Caravan Finance",
        route: "finance/caravan-finance",
        icon: "caravanFinanceIcon",
      },
      {
        text: "Business Finance",
        route: "finance/business-finance",
        icon: "businessFinanceIcon",
      },
      {
        text: "Motorbike Finance",
        route: "finance/motorbike-finance",
        icon: "motorbikeFinanceIcon",
      },
    ],
  },
  { text: "Loan Experts", route: "/loan-experts" },
  {
    text: "About",
    route: "/about",
    subPages: [
      {
        text: "About Us",
        route: "about/about-us",
        icon: "aboutUsIcon",
      },
      {
        text: "Contact Us",
        route: "about/contact-us",
        icon: "contactUsIcon",
      },
    ],
  },
];

export const ASSET_SELECTOR = [
  {
    image: carAsset,
    text: "Car",
    route: "/finance/car-finance",
  },
  {
    image: boatAsset,
    text: "Boat",
    route: "/finance/boat-finance",
  },
  {
    image: caravanAsset,
    text: "Caravan",
    route: "/finance/caravan-finance",
  },
  {
    image: businessAsset,
    text: "Business",
    route: "/finance/business-finance",
  },
];

export const APPLY_ONLINE_URL = "https://apply.simplify.co.nz/application";
